<template lang="pug">
    .listing-card.my-5
        v-hover(v-slot:default="{ hover }"  open-delay="300")
            v-card(color="white", tile, :class="{hovered: hover}" :elevation="hover ? 3 : 2")
                v-row(dense).pl-0.pl-md-2
                    v-col(cols="7").shadow.pa-0
                        v-card-title.pb-0
                            a.title(:title="listing.title | cleanDbText", :href="listing.permalink" target="_blank") {{listing.title | cleanDbText}}
                        v-card-subtitle(v-if="listing.subtitle") {{listing.subtitle}}
                        v-card-text 
                            v-row(dense)
                                v-col(cols="12" shrink)
                                    .description {{listing.description | cleanDbText}}
                                v-col(cols="12" grow)
                                    CardCtaView.mt-5(:listing="listing")
                    v-col(cols="5").py-0
                        .outline
                            CardPrice(:listing="listing")
                            v-img(:src="listing.avatar" min-height="200px" @load="$emit('load')")
                CardMeta(:listing="listing")
                    

                
</template>

<script>
const CardMeta = () => import("@/components/CardMeta.vue");
const CardPrice = () => import("@/components/CardPrice.vue");
const CardCtaView = () => import("@/components/CardCtaView.vue");

export default {
  name: "ListingCard",
  components: { CardMeta, CardPrice, CardCtaView },
  props: { listing: { type: Object, required: true } },
  methods: {
    doHover(e) {
      console.log("hovering on e", e);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";

.header {
  position: relative;
}
a.title {
  color: rgb(10, 51, 140);
  word-break: normal;
  text-decoration: none;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

a.title:hover {
  transition: 0.3s all ease-in-out;
  color: $primary;
  text-decoration: underline;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.shadow {
  z-index: 1;
  box-shadow: 12px 0px 0 rgba(0, 0, 0, 0.2);
}

.p-relative {
  height: 100%;
}
</style>
